div.footer_container {
  background-color: #383838;
  padding: 3%;
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}
div.texto_footer img {
  align-self: center;
  width: 10vw;
  height: 7vw;
}
div.texto_footer {
  display: flex;
  align-items: center;
  width: 50%;
}

div.footer_container .texto_footer p {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 20px;
  margin-left: 5%;
  color: #ffffff;
}

div.footer_container .imagenes_footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
  margin-right: 100px;
}

div.footer_container .imagenes_footer img {
  max-width: 20vw;
  height: 2vw;
  margin-right: 2%;
  border-radius: 20px;
}

@media (max-width: 750px) {
  div.footer_container .texto_footer p {
    text-align: center;
    font-size: 9px;
    margin-left: 0;
    margin-top: 1%;
    line-height: 13px;
  }

  div.footer_container .imagenes_footer img {
    max-width: 80%;
    height: auto;
    border-radius: 20px;
    margin-right: 0;
  }

  div.footer_container .imagenes_footer {
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 100%;
    margin-right: 0px;
  }

  div.texto_footer {
    height: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  div.texto_footer img {
    width: 26vw;
    height: 18vw;
  }
}
